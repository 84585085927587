
import { defineComponent, ref, onMounted } from "vue";
import { ApiBase, ApiMerchant } from "@/core/api";
import _ from "lodash";

interface Filter {
  name: string;
  short_key: string;
  city: string;
  country_iso_2: string;
  status: string;
  merchant_id: string;
}

export default defineComponent({
  name: "filter-store-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const data = ref<Filter>({
      name: "",
      short_key: "",
      city: "",
      country_iso_2: "",
      merchant_id: "",
      status: "10",
    });

    const countryOptions = ref([]);
    const options = ref({
      merchant_id: [],
    });
    const getCountryOptions = () => {
      ApiBase.getCountryData()
        .then(({ data }) => {
          if (data.code == 0) {
            countryOptions.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const getMerchantSourceData = async (value, id?) => {
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData(params);
      if (data.code == 0) {
        options.value.merchant_id = data.data;
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    const submit = () => {
      emit("submit-filter", data.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onMounted(() => {
      getCountryOptions();
    });
    return {
      data,
      formRef,
      submit,
      handleReset,
      countryOptions,
      options,
      searchMerchantSourceItems,
    };
  },
});
