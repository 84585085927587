
import { defineComponent, ref, onMounted, computed } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import addStoresModal from "@/views/sales-channels/online-stores/AddStoresModal.vue";
import FilterDropdwon from "@/views/sales-channels/online-stores/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiStore } from "@/core/api";
import moment from "moment";
import _ from "lodash";
import fileDownload from "js-file-download";
import { formatDateTime, setModuleBCN } from "@/core/directive/function/common";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

interface Store {
  id: number;
  name: string;
  short_key: string;
  city: string;
  country_iso_2: string;
  merchant: string;
}
interface SortOrders {
  field: string;
  direction: string;
}

interface Filter {
  field: string;
  value?: string;
  from?: string;
  to?: string;
  condition?: string;
}

export default defineComponent({
  name: "online-store",
  components: {
    MBDatatable,
    addStoresModal,
    FilterDropdwon,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<Store>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const sortOrders = ref<Array<SortOrders>>([]);
    const disabledExport = ref(false);

    const filterGroup = ref<Array<Filter>>([
      {
        field: "id",
        value: "",
      },
      {
        field: "name",
        value: "",
        condition: "LIKE",
      },
      {
        field: "short_key",
        value: "",
        condition: "LIKE",
      },
      {
        field: "merchant_id",
        value: "",
      },
      {
        field: "status",
        value: "10",
      },
    ]);

    const tableHeader = ref([
      {
        name: "Store ID",
        key: "id",
        sortable: true,
      },
      {
        name: "Online Store Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Parent Merchant",
        key: "merchant_id",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "Creation time",
        key: "created_at",
        sortable: true,
      },
      {
        name: t("common.action"),
        key: "actions",
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getStoreList(
        currentPage.value,
        pageSize.value,
        sortOrders.value,
        filterGroup.value,
        search.value
      );
    });

    const getStoreList = (
      page: number,
      pageSize: number,
      sortOrders?: Array<SortOrders>,
      filterGroup?: Array<Filter>,
      search?: string
    ) => {
      loading.value = true;
      let data = filterGroup?.filter((item) => {
        return item.value != "";
      });
      ApiStore.getStoreList({
        page: page,
        page_size: pageSize,
        sort_orders: sortOrders,
        filter_group: data,
        search_value: search,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getStoreList(
        page,
        pageSize.value,
        sortOrders.value,
        filterGroup.value,
        search.value
      );
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      getStoreList(
        currentPage.value,
        pagesize,
        sortOrders.value,
        filterGroup.value,
        search.value
      );
    };

    const onFilter = (data) => {
      console.log(222);
      let filterArr: Array<Filter> = [];
      for (let item in data) {
        if (
          item == "merchant_id" ||
          item == "country_iso_2" ||
          item == "status"
        ) {
          filterArr.push({
            field: item,
            value: data[item],
            condition: "eq",
          });
        } else if (data[item] != "") {
          filterArr.push({
            field: item,
            value: data[item],
            condition: "like",
          });
        }
      }
      // let filterGroupData = JSON.parse(JSON.stringify(data.value));
      // assemblyFilter(filterGroupData);
      filterGroup.value.splice(0, filterGroup.value.length, ...filterArr);
      getStoreList(
        currentPage.value,
        pageSize.value,
        sortOrders.value,
        filterGroup.value,
        search.value
      );
    };

    const assemblyFilter = (data) => {
      filterGroup.value.forEach((item) => {
        item.value = data[item.field];
      });
    };

    const debounceSearch = _.debounce(getStoreList, 1000);

    const searchItems = () => {
      debounceSearch(
        currentPage.value,
        pageSize.value,
        sortOrders.value,
        filterGroup.value,
        search.value
      );
    };

    const renew = () => {
      getStoreList(
        currentPage.value,
        pageSize.value,
        sortOrders.value,
        filterGroup.value,
        search.value
      );
    };

    const onColumnSort = (val) => {
      let sortObj: SortOrders = {
        field: val.columnName,
        direction: val.order,
      };
      sortOrders.value.splice(0, sortOrders.value.length, sortObj);
      getStoreList(
        currentPage.value,
        pageSize.value,
        sortOrders.value,
        filterGroup.value,
        search.value
      );
    };

    const fileExport = () => {
      disabledExport.value = true;
      ApiStore.storePrint({
        search_value: search.value,
        filter_group: filterGroup.value,
        sort_orders: sortOrders.value,
        download_data_type: "stream",
      })
        .then((data) => {
          disabledExport.value = false;
          const disposition = data.headers["content-disposition"];
          fileDownload(data.data, disposition.split("filename=")[1]);
        })
        .catch((error) => {
          disabledExport.value = false;
          console.log(error);
        });
    };
    const resetFilter = () => {
      filterGroup.value.splice(0, filterGroup.value.length);
    };
    const handleFilterReset = () => {
      resetFilter();
      getStoreList(
        currentPage.value,
        pageSize.value,
        sortOrders.value,
        filterGroup.value,
        search.value
      );
    };

    return {
      formatDateTime,
      loading,
      tableHeader,
      tableData,
      filterGroup,
      currentPage,
      total,
      pageSize,
      search,
      getStoreList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onFilter,
      searchItems,
      sortOrders,
      assemblyFilter,
      onColumnSort,
      renew,
      disabledExport,
      fileExport,
      handleFilterReset,
    };
  },
});
