
import { defineComponent, onMounted, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiStore, ApiMerchant, ApiBase } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import _ from "lodash";

export default defineComponent({
  name: "add-stores-modal",
  components: {},
  emits: ["update-list"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const addStoresModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      status: 10,
      name: "",
      short_key: "",
      email: "",
      website: "",
      telephone: "",
      telephone2: "",
      fax: "",
      merchant_id: "",
      overall_notes: "",
      inter_contact: "",
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Stores Name is required",
          trigger: "change",
        },
      ],
      short_key: [
        {
          required: true,
          message: "Stores Short-Key is required",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: "Email is required",
          trigger: "change",
        },
      ],
      telephone: [
        {
          required: true,
          message: "Telephone is required",
          trigger: "change",
        },
      ],
      merchant_id: [
        {
          required: true,
          message: "Parent Merchant is required",
          trigger: "change",
        },
      ],
    });

    /******************************** 
      获取下拉框数据 
      merchant_id 商户
      country_iso_2国家
    *********************************/
    const options = ref({
      merchant_id: [],
      country_iso_2: [],
    });

    // const getCountryOptions = () => {
    //   ApiBase.getCountryData()
    //     .then(({ data }) => {
    //       if (data.code == 0) {
    //         options.value.country_iso_2 = data.data;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // };
    const getMerchantSourceData = async (value, id?) => {
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData(params);
      if (data.code == 0) {
        options.value.merchant_id = data.data;
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };
    /******************************** 
      获取下拉框数据 end
    *********************************/

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiStore.addStore(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                  hideModal(addStoresModalRef.value);
                  emit("update-list");
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      hideModal(addStoresModalRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    return {
      loading,
      formData,
      options,
      rules,
      formRef,
      addStoresModalRef,
      submitButton,
      submit,
      handleDiscard,
      resetForm,
      searchMerchantSourceItems,
    };
  },
});
